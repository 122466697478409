@import-normalize; /* bring in normalize.css styles */
@import url('https://fonts.googleapis.com/css2?family=Istok+Web&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: flex;
  height: 100vh; /* Adjust the height as needed */
}

.section-one {
  background: linear-gradient(180deg, #163085 -21.08%, #112556 61.4%), #000;
  flex: 1; /* 1 part of the space */
}

.section-two {
  flex: 2; /* 2 parts of the space */
}

.section-one-top{
  margin-left: 5%;
  margin-top: 5%;
}


.main-content {
  flex-grow: 1;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.responsive-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 1em;
}

/* Responsive Styles */
@media (max-width: 768px) {
  /* Styles for tablets and smaller devices */


  .main-content {
    padding: 0.5em;
  }
}

@media (max-width: 480px) {

  .main-content {
    padding: 0.25em;
  }

  .responsive-image {
    width: 90%; /* Adjust the image size for small screens */
  }
}

.sec-mid-text{
  color: #FFF;
text-align: center;
font-family: Istok Web;
font-size: 64px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.704px;
}

.sec-mid-text2{
  color: #FFF;
text-align: center;
font-family: Istok Web;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.264px;
align-self: stretch;
}

.top-right {
  top: 0px;
  position: absolute;
  right: 10%;
  display: flex;
  flex-direction: row;
}

.top-right .RegisterButton {
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.264px;
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 0px 0px 8px 8px;
  background: var(--s-primary-source, #273F77);
  cursor: pointer;
  border: 0px;
}

.top-right .LoginButton {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-family: Inter;
  font-size: 20px; /* Adjusted for consistency */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.264px;
  cursor: pointer;
  background-color: white;
  border: 0px;
}

/* Mobile-friendly adjustments */
@media screen and (max-width: 768px) {
  .top-right {
    right: 1px; /* Adjust as needed for smaller screens */
    flex-direction: column; /* Stack buttons vertically on smaller screens */
  }

  .top-right .RegisterButton,
  .top-right .LoginButton {
    font-size: 18px; /* Slightly smaller font size for smaller screens */
    padding: 12px 18px; /* Adjust padding for smaller screens */
    gap: 4px; /* Reduce gap for smaller screens */
  }
}

#FormYear{
  width: 260px;
}

#FormName, #FormEmail, #FormPhone, #FormBranch,#FormHostel{
  background-color: white !important;
  width: 225px;
  border-color: #c4c4c4;
  
}

#FormPassword2 , #FormEmail2{
  background-color: white !important;
  width: 325px;
}

.MuiInputBase-formControl{
  height: 44px;
  border: 0px;
}

.cancelButton{
  background-color: white !important;
  color: #5C5C5C !important;
  width: 250px;

}
 .submitButton{
  border: 2px solid var(--Primary-Primary01, #212121) !important;
  background: var(--s-accent-color-source, #E79A2B) !important;
  width: 250px;

}


.AlreadyText{
  position: absolute;
  right: 0px;
  text-align: right;
}

.Form{
  width: 60%; /* or any fixed width */
  margin-left: auto;
  margin-right: auto;
}

.Form2{
  width: 60%; /* or any fixed width */
  margin-left: auto;
  margin-right: auto;
}

.appBarTop{
  background: #F2F3FC !important;
  margin-left: 112px;
  width: 93.3% !important;
  overflow: "hidden";
}

.poweredBy{
  color: var(--Primary-Primary01, #212121) !important;
font-family: Roboto;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.drawer{
  left: 24px ;
  top: 20px;
}

.sidebarTop{
  margin-left: 24px;
  margin-top: 20px;
}

.sidebarButton {
  height: 60px;
  border-radius: 8px !important;
  background: var(--s-primary-source, #273F77) !important;
  width: 60px;
  margin-top: 10px;
}

.sidebarButton:hover{
  background: var(--s-accent-color-source, #E79A2B) !important;
}

.activeSidebarButton{
  background: var(--s-accent-color-source, #E79A2B) !important;
}

.mainContent{
  /* margin-left: 70px;
  margin-top: 70px; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120vh;
}

.mainContentTable{
  /* margin-left: 70px;
  margin-top: 70px; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120vh;
}

.noProjectContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:15px;
}

.noProjectTextTitle{
  color: var(--s-primary-source, #273F77);
text-align: center;
font-family: Inter;
font-size: 36px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 54px */
letter-spacing: -0.396px;
}

.noProjectTextContent{
  color: var(--s-primary-700, #475890);
text-align: center;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 36px */
letter-spacing: -0.264px;
}


.cardsContainer {
  margin-left: 10%;
  margin-top: 60%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* This adds space between the items; adjust as necessary */
}


.cardsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* To evenly space cards in a row */
}

/* Optional: Adjust card width as needed */
.CardComponent {
  width: calc(33.33% - 16px);
   /* 33.33% for 3 cards per row, 16px for spacing */
  margin-bottom: 16px; /* Adjust the margin between rows */
}